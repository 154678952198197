"use strict";

var _interopRequireDefault = require("D:/www/erp/back-end/client-vue2/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _waves = _interopRequireDefault(require("./waves"));
var install = function install(Vue) {
  Vue.directive('waves', _waves.default);
};
if (window.Vue) {
  window.waves = _waves.default;
  Vue.use(install); // eslint-disable-line
}

_waves.default.install = install;
var _default = _waves.default;
exports.default = _default;