"use strict";

var _interopRequireDefault = require("D:/www/erp/back-end/client-vue2/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getEncrypt = getEncrypt;
require("core-js/modules/es6.array.sort");
var _typeof2 = _interopRequireDefault(require("D:/www/erp/back-end/client-vue2/node_modules/@babel/runtime/helpers/typeof.js"));
var _crypto = _interopRequireDefault(require("crypto"));
var _qs = _interopRequireDefault(require("qs"));
/**
 * 数据加密
 * @param {*} obj 加密对象
 * @param {*} signKey 加密盐值
 */
function getEncrypt(obj, signKey) {
  var arr = [];
  var keys = [];
  for (var i in obj) {
    if ((0, _typeof2.default)(obj[i]) !== 'object' && i !== 'signature') {
      keys.push(i);
    }
  }
  keys.sort();
  for (var _i in keys) {
    arr[keys[_i]] = obj[keys[_i]];
  }
  var arr_str = _qs.default.stringify(arr);
  var arr_sha1 = _crypto.default.createHash('sha1').update(arr_str).digest('hex');
  arr_sha1 = arr_sha1 + signKey;
  return _crypto.default.createHash('md5').update(arr_sha1).digest('hex');
}