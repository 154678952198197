var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tags-view-container" },
    [
      _c(
        "scroll-pane",
        { ref: "scrollPane", staticClass: "tags-view-wrapper" },
        [
          _vm.layout === "left"
            ? _c("hamburger", {
                staticClass: "hamburger-container",
                attrs: {
                  id: "hamburger-container",
                  "is-active": _vm.sidebar.opened,
                },
                on: { toggleClick: _vm.toggleSideBar },
              })
            : _vm._e(),
          _vm._l(_vm.visitedViews, function (tag) {
            return _c(
              "router-link",
              {
                key: tag.path,
                ref: "tag",
                refInFor: true,
                staticClass: "tags-view-item",
                class: _vm.isActive(tag) ? "active" : "",
                attrs: {
                  to: {
                    path: tag.path,
                    query: tag.query,
                    fullPath: tag.fullPath,
                  },
                  tag: "span",
                },
                nativeOn: {
                  mouseup: function ($event) {
                    if ("button" in $event && $event.button !== 1) {
                      return null
                    }
                    !_vm.isAffix(tag) ? _vm.closeSelectedTag(tag) : ""
                  },
                  contextmenu: function ($event) {
                    $event.preventDefault()
                    return _vm.openMenu(tag, $event)
                  },
                },
              },
              [
                _vm._v(" " + _vm._s(tag.title) + " "),
                !_vm.isAffix(tag)
                  ? _c("span", {
                      staticClass: "el-icon-close",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                          return _vm.closeSelectedTag(tag)
                        },
                      },
                    })
                  : _vm._e(),
              ]
            )
          }),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "tags-tools-container" },
        [
          _vm.user.pos_id
            ? _c(
                "el-dropdown",
                { staticClass: "margin-right", attrs: { trigger: "click" } },
                [
                  _c("div", [
                    _c("span", [_vm._v(_vm._s(_vm.user.pos_title))]),
                    _c("i", { staticClass: "el-icon-caret-bottom" }),
                  ]),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _vm._l(_vm.pos_owner, function (item, index) {
                        return _c(
                          "el-dropdown-item",
                          {
                            key: item.id,
                            staticStyle: { display: "block" },
                            attrs: { divided: index !== 0 },
                          },
                          [
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.handleChangePos(item.id)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(item.title))]
                            ),
                          ]
                        )
                      }),
                      _c(
                        "el-dropdown-item",
                        {
                          staticStyle: {
                            display: "block",
                            "text-align": "center",
                          },
                          attrs: { divided: "" },
                        },
                        [
                          _c("span", { on: { click: _vm.handleAddPos } }, [
                            _vm._v("新增门店"),
                          ]),
                        ]
                      ),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.device !== "mobile"
            ? _c(
                "el-tooltip",
                {
                  attrs: {
                    content: "全屏",
                    effect: "dark",
                    placement: "bottom",
                  },
                },
                [
                  _c("screenfull", {
                    staticClass: "hover-effect",
                    attrs: { id: "screenfull" },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.msg.length
            ? _c(
                "el-tooltip",
                {
                  attrs: {
                    content: "消息",
                    effect: "dark",
                    placement: "bottom",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "message-tags" },
                    [
                      _c(
                        "el-badge",
                        { staticClass: "item", attrs: { "is-dot": "" } },
                        [
                          _c(
                            "span",
                            { on: { click: _vm.openMsg } },
                            [
                              _c("svg-icon", {
                                staticClass: "text-xl",
                                attrs: { "icon-class": "message" },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm.debug
            ? _c(
                "el-tooltip",
                {
                  staticClass: "pointer",
                  attrs: {
                    effect: "dark",
                    content: "内置图标",
                    placement: "bottom",
                  },
                },
                [
                  _c(
                    "router-link",
                    { attrs: { to: { path: "/icons" } } },
                    [
                      _c("svg-icon", {
                        staticClass: "text-xl",
                        attrs: { "icon-class": "icon" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-tooltip",
            {
              directives: [
                { name: "auth", rawName: "v-auth:core_app", arg: "core_app" },
              ],
              staticClass: "pointer",
              attrs: {
                effect: "dark",
                content: "应用中心",
                placement: "bottom",
              },
            },
            [
              _c(
                "router-link",
                { attrs: { to: { path: "/core/app" } } },
                [
                  _c("svg-icon", {
                    staticStyle: { "font-size": "26px" },
                    attrs: { "icon-class": "appstore" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.layout === "left" ? _c("profile") : _vm._e(),
        ],
        1
      ),
      _c(
        "ul",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.visible,
              expression: "visible",
            },
          ],
          staticClass: "contextmenu",
          style: { left: _vm.left + "px", top: _vm.top + "px" },
        },
        [
          _c(
            "li",
            {
              on: {
                click: function ($event) {
                  return _vm.refreshSelectedTag(_vm.selectedTag)
                },
              },
            },
            [_vm._v("刷新")]
          ),
          !_vm.isAffix(_vm.selectedTag)
            ? _c(
                "li",
                {
                  on: {
                    click: function ($event) {
                      return _vm.closeSelectedTag(_vm.selectedTag)
                    },
                  },
                },
                [_vm._v("关闭")]
              )
            : _vm._e(),
          _c("li", { on: { click: _vm.closeOthersTags } }, [
            _vm._v("关闭其它"),
          ]),
          _c(
            "li",
            {
              on: {
                click: function ($event) {
                  return _vm.closeAllTags(_vm.selectedTag)
                },
              },
            },
            [_vm._v("全部关闭")]
          ),
        ]
      ),
      _c("msg", { ref: "msg" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }