"use strict";

var _interopRequireDefault = require("D:/www/erp/back-end/client-vue2/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getInfo = getInfo;
exports.getList = getList;
exports.resetList = resetList;
exports.update = update;
exports.updateList = updateList;
var _request = _interopRequireDefault(require("@/utils/request"));
function getInfo() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: '/core/config/index',
    method: 'get',
    params: params
  });
}
function getList() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: '/firm/config/list',
    method: 'get',
    params: params
  });
}
function update() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: '/firm/config/update',
    method: 'post',
    data: data
  });
}
function updateList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: '/firm/config/updateList',
    method: 'post',
    data: data
  });
}
function resetList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: '/firm/config/resetList',
    method: 'post',
    data: data
  });
}