"use strict";

var _interopRequireDefault = require("D:/www/erp/back-end/client-vue2/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.buy = buy;
exports.create = create;
exports.exportList = exportList;
exports.getInfo = getInfo;
exports.getList = getList;
exports.getLocation = getLocation;
exports.getOwner = getOwner;
exports.getPage = getPage;
exports.importList = importList;
exports.login = login;
exports.remove = remove;
exports.removeList = removeList;
exports.suggestion = suggestion;
exports.transition = transition;
exports.update = update;
exports.updateList = updateList;
exports.updateStatus = updateStatus;
var _request = _interopRequireDefault(require("@/utils/request"));
function getPage() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: '/firm/pos/page',
    method: 'get',
    params: params
  });
}
function getList() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: '/firm/pos/list',
    method: 'get',
    params: params
  });
}
function getInfo(id) {
  return (0, _request.default)({
    url: '/firm/pos/info',
    method: 'get',
    params: {
      id: id
    }
  });
}
function create(data) {
  return (0, _request.default)({
    url: '/firm/pos/create',
    method: 'post',
    data: data
  });
}
function update(data) {
  return (0, _request.default)({
    url: '/firm/pos/update',
    method: 'post',
    data: data
  });
}
function remove(data) {
  return (0, _request.default)({
    url: '/firm/pos/remove',
    method: 'post',
    data: data
  });
}
function updateStatus(data) {
  return (0, _request.default)({
    url: '/firm/pos/updateStatus',
    method: 'post',
    data: data
  });
}
function updateList(data) {
  return (0, _request.default)({
    url: '/firm/pos/updateList',
    method: 'post',
    data: data
  });
}
function removeList(data) {
  return (0, _request.default)({
    url: '/firm/pos/removeList',
    method: 'post',
    data: data
  });
}
function importList(data) {
  return (0, _request.default)({
    url: '/firm/pos/importList',
    method: 'post',
    data: data,
    timeout: 180000
  });
}
function exportList() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: '/firm/pos/exportList',
    method: 'get',
    params: params
  });
}
function getLocation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: '/firm/pos/location',
    method: 'get',
    params: params
  });
}
function login() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: '/firm/pos/login',
    method: 'get',
    params: params
  });
}
function getOwner() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: '/firm/pos/owner',
    method: 'get',
    params: params
  });
}
function buy() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: '/firm/pos/buy',
    method: 'get',
    params: params
  });
}
function suggestion() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: '/firm/pos/suggestion',
    method: 'get',
    params: params
  });
}
function transition() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: '/firm/pos/transition',
    method: 'get',
    params: params
  });
}