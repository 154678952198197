"use strict";

var _interopRequireDefault = require("D:/www/erp/back-end/client-vue2/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.create = create;
exports.getInfo = getInfo;
exports.getList = getList;
exports.getPage = getPage;
exports.remove = remove;
exports.restore = restore;
exports.update = update;
var _request = _interopRequireDefault(require("@/utils/request"));
function getPage() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: '/storage/folder/page',
    method: 'get',
    params: params
  });
}
function getList() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: '/storage/folder/list',
    method: 'get',
    params: params
  });
}
function getInfo(id) {
  return (0, _request.default)({
    url: '/storage/folder/info',
    method: 'get',
    params: {
      id: id
    }
  });
}
function create(data) {
  return (0, _request.default)({
    url: '/storage/folder/create',
    method: 'post',
    data: data
  });
}
function update(data) {
  return (0, _request.default)({
    url: '/storage/folder/update',
    method: 'post',
    data: data
  });
}
function remove(data) {
  return (0, _request.default)({
    url: '/storage/folder/remove',
    method: 'post',
    data: data
  });
}
function restore(data) {
  return (0, _request.default)({
    url: '/storage/folder/restore',
    method: 'post',
    data: data
  });
}