"use strict";

var _interopRequireDefault = require("D:/www/erp/back-end/client-vue2/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSignature = getSignature;
exports.getToken = getToken;
exports.removeToken = removeToken;
exports.setToken = setToken;
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _encrypt = require("@/utils/encrypt");
var _setting = _interopRequireDefault(require("@/setting.js"));
var TokenKey = 'User-Token';

/**
 * 获取token
 */
function getToken() {
  return _jsCookie.default.get(TokenKey);
}

/**
 * 设置token
 * @param {*} token
 */
function setToken(token) {
  return _jsCookie.default.set(TokenKey, token, {
    expires: _setting.default.cookieExpires
  });
}

/**
 * 删除token
 */
function removeToken() {
  return _jsCookie.default.remove(TokenKey);
}

/**
 * 数据签名
 * @param {*} obj
 */
function getSignature(obj) {
  var encrypt = {};
  if (obj) {
    encrypt = obj;
  }
  // 签名
  var signature = (0, _encrypt.getEncrypt)(encrypt, _setting.default.appSecret);
  encrypt.signature = signature;
  return encrypt;
}