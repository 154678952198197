import { render, staticRenderFns } from "./Msg.vue?vue&type=template&id=07588add&scoped=true&"
import script from "./Msg.vue?vue&type=script&lang=js&"
export * from "./Msg.vue?vue&type=script&lang=js&"
import style0 from "./Msg.vue?vue&type=style&index=0&id=07588add&rel=stylesheet%2Fscss&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07588add",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\www\\erp\\back-end\\client-vue2\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('07588add')) {
      api.createRecord('07588add', component.options)
    } else {
      api.reload('07588add', component.options)
    }
    module.hot.accept("./Msg.vue?vue&type=template&id=07588add&scoped=true&", function () {
      api.rerender('07588add', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/layout/components/TagsView/Msg.vue"
export default component.exports